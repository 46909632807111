// COLORS

$color-white : #fff;
$color-orange : #FF6727;
$color-red : #E35D6A;
$color-yellow : #FFCD39;
$color-teal : #4DD4AC;
$color-teal-white : #DBF6EE;
$color-purple : #8C68CD;
$color-blue : #3D8BFD;
$color-cyan : #3DD5F3;
$color-teal-bg: #F8FFFD;
$color-black: #191414;
$color-grey: #777777;
$color-grey-white: #666666;
$color-grey-dark: #393939;

// BREAKPOINTS (used by other college)
$breakpoint-desktops: 1200px;
$breakpoint-tablet: 992px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-s: 575px;
