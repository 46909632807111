/* START HEADER */

.sticky{
  position: fixed !important;
  top: 0;

  left: 0;
  width: 100%;
  z-index: 999;
  background-color: $color-white;


  @media (min-width: $breakpoint-tablet) {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: $color-white;

  }
}
.sticky-bs {
  box-shadow: 0px 10rem 8rem rgb(0 0 0 / 0), 0px 2.2rem 1.8rem rgb(0 0 0 / -1%), 0px 0.7rem 0.5rem rgb(0 0 0 / 3%);
  @media (min-width: $breakpoint-tablet) {
    box-shadow: 0px 10rem 8rem rgba(0, 0, 0, 0.07),
    0px 2.2rem 1.8rem rgba(0, 0, 0, 0.04), 0px 0.7rem 0.5rem rgba(0, 0, 0, 0.03);

  }
}
.site-header {
    position: relative;

    transition: all 0.3s;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 52px;
      transition: none;
      &.mobile-active {
        &.sticky {
          overflow: auto;
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          overflow-y: auto;
        }
        width: 100%;
        height: 100%;
      }
    }
    .header-wrapper{
      background-color: $color-white;

      margin: 0 -15px;
      .top-header {
        height: 35px;
        background-color: $color-teal-white;
        position: relative;
        transition: all 0.5s;
        &.hide {
          @media (min-width: $breakpoint-tablet) {
            height: 0;
            overflow: hidden;
          }
        }
        @media (max-width: $breakpoint-tablet) {
          height: 35px;
          display: none;
        }
        .top-header-login, .top-header-profile {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          cursor: pointer;
           .top-header-login-wrapper {
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
            margin-right: 15px;
            img {
              margin-right: 5px;
            }
          }
        }
        ul {
          display: flex;
          justify-content: center;
          height: 100%;
          align-items: center;
          li {
            padding-right: 17px;
            margin-right: 25px;
            line-height: 12px;
            border-right: 1px solid $color-black;
            &:last-of-type {
              margin-right: 0;
              border-right:none;
            }
          }
        }
      }
      .search-content {
        display: none;
        position: absolute;
        right: 0;
        width: 80%;
        background-color: $color-teal-bg;
        z-index: 9;
        height: calc(100% - 35px);
        align-items: center;
        justify-content: flex-end;
        z-index: 999999999999999999;
        img {
          cursor: pointer;
        }
        form {
          border-bottom: 1px solid $color-teal;
          margin-right: 35px;
          width: 100%;
          position: relative;
          padding: 12px 0;
          input {
            border: none;
            outline: none;
            width: 100%;
            padding-left: 10px;
            background-color: $color-teal-bg;
          }
          img {
            position: absolute;
            right: 10px;
          }
        }
      }
      .open-menu {
        left: -1px;
        right: 1px;
        width: auto;
        margin: 0 -15px;
        padding: 0 15px;
        top: 60px;
        position: absolute;
        padding-top: 85px;
        background-color: #fff;
        // z-index: 99999999;
        z-index: auto;
        display: none;
        max-height: 90vh;
        overflow: auto;
        &.tablet-res {

          @media (max-width: $breakpoint-tablet) {
            display: block !important;
            position: absolute;
            padding: 0;
            .menu-list {
              display: none;
            }
            .menu-bottom {
              li {
                display: none !important;
              }
              .mobile-list-podcast {
                display: block !important;
                height: 32px;
                background-color: $color-teal-bg;
                text-align: center;
                box-shadow:  0px 10rem 8rem rgb(0 0 0 / 7%), 0px 2.2rem 1.8rem rgb(0 0 0 / 4%), 0px 0.7rem 0.5rem rgb(0 0 0 / 3%);
                left: 0;
                right: 0;
                position: fixed;
                top: 59px;
                transition: all 0.5s;
                overflow: hidden;
                &.hide {
                  @media (max-width: $breakpoint-tablet) {
                    height: 0;
                    padding: 0;
                  }
                }
               img {
                 top: 3px;
                 position: relative;
               }
              }
            }
           }
        }
        &.mobile-active {
          .open-menu-content {
            .open-list {
              display: block !important;
            }
            .menu-list {
              ul {
                position: relative;

                &.active {
                  img {
                    transform: rotateX(180deg);
                  }
                }
                li {
                  a {
                    font-size: 20px;
                  }
                  display: none;
                  &.open-list {
                    &:last-of-type {
                      margin-bottom: 25px;
                    }
                    a {
                      color: $color-teal;
                    }
                  }
                  &.h-list {
                    display: block;
                  }
                }
              }
            }
          }
        }
        .open-menu-content {
          .menu-list {
            margin-bottom: 50px;

            @media (max-width: $breakpoint-tablet) {
              margin-bottom: 0;
              background-color: $color-white;
            }
            ul {

              img {
                display: none;
                @media (max-width: $breakpoint-tablet) {
                  position: absolute;
                  right: 12px;
                  top: 10px;
                  display: block;
                }
              }
              @media (max-width: $breakpoint-tablet) {
                margin-bottom: 0;
              }
              li {
                display: block;
                margin-bottom: 10px;

                @media (max-width: $breakpoint-tablet) {
                  display: none;
                }
                &.h-list {
                  margin-bottom: 15px;
                  font-weight: bold;
                  @media (max-width: $breakpoint-tablet) {
                    margin-bottom: 20px;
                    font-weight: normal;
                  }
                  // font-family: $sourceSansSemiBold;
                }
              }
            }
          }
          .menu-bottom {
            background-color: $color-white;

            @media (min-width: $breakpoint-tablet) {
              box-shadow: 0px 100px 80px rgb(0 0 0 / 7%);
              position: relative;
            }
            ul {
              display: flex;
              column-gap: 25px;
              width: 77%;
              padding-bottom: 25px;

              @media (max-width: $breakpoint-tablet) {
                flex-direction: column;
                width: 100%;
                padding: 0 15px;
              }
              li {
                display: flex;
                column-gap: 5px;
                @media (max-width: $breakpoint-tablet) {
                  align-items: center;
                  padding-bottom: 25px;
                  font-size: 18px;
                  column-gap: 10px;
                }
                .mobile-img {
                  display: none;
                  @media (max-width: $breakpoint-tablet) {
                    display: inline-block;
                    width: auto;
                    height: auto;
                  }
                }
                img {
                  display: inline-block;
                  @media (max-width: $breakpoint-tablet) {
                    display: none;
                  }
                  width: 20px;
                  height: 20px;
                }
                &.mobile-list-podcast {

                  @media (max-width: $breakpoint-tablet) {
                    // position: absolute;
                  }
                  a {
                    vertical-align: sub;
                    color: $color-teal;
                  }
                }
              }
            }
            .menu-login {
              position: absolute;
              right: 0;
              width: 23%;
              bottom: 0;
              text-align: right;
              background-image: url('../../images/sections/menu-bg.svg');
              background-size: cover;
              padding: 25px 0;
              padding-right: 18px;

              @media (max-width: $breakpoint-tablet) {
                display: none;
              }
              p {
                margin-bottom: 15px;
                line-height: 17px;
              }
            }
          }
        }
      }
      .site-nav {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 90px;
        align-items: center;
        position: relative;
        padding: 0 15px;
        background-color: $color-teal-bg !important;
        // margin-bottom: 10px;
        @media (max-width: $breakpoint-tablet) {
          align-items: flex-start;
          padding-top: 15px;
          height: 60px;
          margin-bottom: 0;
          background-color: $color-white !important;
        }
        .logo-wrapper-mobile {
          @media (min-width: $breakpoint-tablet) {
            display: none;
          }
        }
        .logo-wrapper {
          // padding-top: 10px;
          z-index: 999999999999999999;
          @media (max-width: $breakpoint-tablet) {
            display: none;
          }
        }
        .nav-mobile {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 50px;
        z-index: 999999999;
        padding: 0 15px;
        padding-top: 20px;
        background-color: $color-white;
        .mobile-btns {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 20px;
          margin-bottom: 20px;
          font-size: 20px;
          form {
            width: 100%;
          }
          .mobile-btn-enter,
          .mobile-btn-search{

            transition: all 0.3s;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 48%;
            height: 50px;
            border-radius: 80px;
            &.mobile-btn-enter {
              border: 1px solid $color-orange;
              img {
                margin-right: 10px;
                width: 24px;
              }
            }
            &.mobile-btn-search {
              border: 1px solid $color-teal;
              overflow: hidden;
              &.active {
                flex: 0 0 100%;
                justify-content: flex-end;
                align-items: center;
                input {
                  width: 100%;
                  padding-right: 40px;
                  padding-left: 20px;
                }
                img {
                  position: absolute;
                  right: 30px;
                  top: 32px;
                }
              }
              input {
                &::placeholder {
                  color: $color-black;
                }
                width: 50%;
                border: none;
                padding-left: 10px;
                outline: none;
                height: 45px;
                border-radius: 10px;
                font-size: 20px;
              }
              img {
                width: 24px;
                margin-left: 10px;
              }
            }
          }
        }
      }
        .nav {
          display: flex;
          flex-direction: column;
          // padding-top: 10px;
          @media (max-width: $breakpoint-tablet) {
            padding-top: 0;
          }
          .top-nav {
            margin-bottom: 15px;
            font-size: 14px;
            @media (max-width: $breakpoint-tablet) {
              display: none;
            }
            ul {
              list-style-type: none;
              display: flex;
              column-gap: 25px;
              justify-content: flex-end;
              li {
                a {
                  display: flex;
                  column-gap: 5px;
                }
              }
            }
          }
          .btm-nav {
            ul {
              list-style-type: none;
              display: flex;
              column-gap: 25px;
              justify-content: flex-end;
              li {
                a {
                  text-transform: uppercase;
                  font-size: 15px;
                  line-height: 19px;
                }
                @media (max-width: $breakpoint-tablet) {
                  display: none;
                }
              }
            }
            .menu-btn-mobile {
                @media (min-width: $breakpoint-tablet) {
                  display: none;
                }
                @media (max-width: $breakpoint-tablet) {
                  display: block;
                }
                background-image: url('../../images/sections//menu-btn-mobile.svg');
                background-repeat: no-repeat;
                background-size: contain;
                width: 40px;
                height: 35px;
                background-position: center right;
                background-position-y: -5px;
                &.active {
                  background-image: url('../../images/glyphicons/close-menu.svg');
                  width: 25px;
                  height: 25px;
                  background-position: center center;
                }
              }
            }
            .menu-btn {
              cursor: pointer;
              @media (max-width: $breakpoint-tablet) {
                display: none;
              }
            }
          }
        }
      }
    }
    // .mobile-header {
    //   display: none;
    //   width: 100%;
    //   .mobile-open-menu {
    //     display: none;
    //     .mobile-btns {
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       column-gap: 20px;
    //       margin-bottom: 20px;
    //       a {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         flex: 0 0 49%;
    //         height: 50px;
    //         border-radius: 80px;
    //         &.mobile-btn-enter {
    //           border: 1px solid $color-orange;
    //         }
    //         &.mobile-btn-search {
    //           border: 1px solid $color-teal;
    //           img {
    //             margin-left: 10px;
    //           }
    //         }
    //       }
    //     }
    //     .mobile-navigation-list {
    //       display: flex;
    //       flex-direction: column;

    //       & > li {
    //         margin: 0 0 20px 0;
    //         position: relative;
    //         display: flex;
    //         align-items: center;
    //         & > img {
    //           position: absolute;
    //           right: 0;
    //         }
    //       }
    //       .last-nav-li {
    //           a {
    //             display: flex;
    //             color: $color-teal;
    //             img {
    //               margin-left: 10px;
    //             }
    //           }
    //       }
    //       .mobile-navigation-nested-list.active {
    //         display: block;
    //         img {
    //           transform: rotateX(180deg);
    //           top: 5px;
    //         }
    //       }
    //       .mobile-navigation-dropdown {
    //         display: none;
    //       }
    //       .mobile-navigation-dropdown.active {
    //         display: flex;
    //         flex-direction: column;
    //         padding-top: 20px;
    //         li {
    //           margin-bottom: 15px;
    //           a {
    //             color: $color-orange;
    //           }
    //         }
    //       }
    //     }
    //     .mobile-menu-bottom {
    //       margin-top: 5px;
    //       ul {
    //         li {
    //           margin-bottom: 20px;
    //           a {
    //             display: flex;
    //             align-items: center;
    //             img {
    //               margin-right: 10px;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }

    //   .mobile-header-top {
    //     height: 60px;
    //     display: flex;
    //     width: 100%;
    //     justify-content: space-between;
    //     align-items: center;
    //     .mobile-logo-nav {
    //       display: flex;
    //     }
    //     .mobile-menu-btn {
    //       background-image: url('../../images/sections//menu-btn-mobile.svg');
    //       background-repeat: no-repeat;
    //       background-size: contain;
    //       width: 40px;
    //       height: 35px;
    //       background-position: center right;
    //     }
    //     .mobile-menu-btn.active {
    //       background-image: url('../../images/glyphicons/close-menu.svg');
    //       width: 25px;
    //       height: 25px;
    //       background-position: center center;
    //     }
    //   }
    //   .mobile-header-bottom {
    //     height: 32px;
    //     background-color: $color-teal-bg;
    //     padding: 0 15px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     a {
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       img {
    //         width: 20px;
    //         height: 20px;
    //       }
    //       span {
    //         font-size: 16px;
    //         margin-left: 7px;
    //         color: $color-teal;
    //       }
    //     }
    //   }
    //   @media (max-width: $breakpoint-desktops) {
    //     display: flex;
    //     flex-direction: column;
    //   }
    // }
// }

  /* END HEADER */
