.mb-25 {
  margin-bottom: 25px;
}

.fb-link-msg {
  display: none;

  @media (max-width: $breakpoint-mobile) {
    display: inline-block;
  }
}

.progress-container {
  width: 100%;
  height: 5px;
  background: transparent;
  position: fixed;
  z-index: 99999999;
  top: 0;

  .progress-bar {
    height: 5px;
    background: $color-teal;
    width: 0%;
  }
}

#responsive-shortcuts-dropdown,
.slicknav_menu {
  display: none !important;
}

body,
main,
html,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'SourceSans' !important;
}

.main-margin {
  margin-top: 155px;

  @media (max-width: $breakpoint-tablet) {
    margin-top: 110px;
  }
}

.gallery1 {
  &::before {
    @media (max-width: $breakpoint-mobile-s) {
      content: '';
      position: absolute;
      background-image: url(../../images/sections/list-2.svg);
      background-repeat: no-repeat;
      background-position: revert;
      width: 40px;
      height: 40px;
      background-size: cover;
      left: 10px;
      bottom: 10px;
      z-index: -1;
    }
  }
}

main {
  margin-top: 135px;

  @media (max-width: $breakpoint-tablet) {
    margin-top: 110px;
  }

  &::before {
    @media (max-width: $breakpoint-mobile-s) {
      content: '';
      position: absolute;
      background-image: url(../../images/bg/bg16.png);
      background-repeat: no-repeat;
      background-position: revert;
      width: 100vw;
      height: 100%;
      background-size: cover;
      max-height: 1300px;
      bottom: -100px;
      right: -1px;
      z-index: -1;
    }
  }
}

h2 {
  color: $color-black;
  font-size: 36px;
  line-height: 55px;
}

.list-url {
  padding: 0 15px;

  .title-list-url {
    color: $color-black;
    font-size: 32px;
    line-height: 55px;
  }

  li {
    height: 40px;

    a {
      color: $color-orange;
      font-size: 22px;
      line-height: 55px;
      margin: 0 5px 0 15px;
    }
  }
}

.mobile-none {
  @media (max-width: $breakpoint-tablet) {
    display: none !important;
  }
}

.mobile-only {
  display: none !important;
}

.mobile-only {
  @media (max-width: $breakpoint-tablet) {
    display: flex !important;
  }
}

.btn {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  border: none;
  margin: 0 auto;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
}

.teal-btn {
  width: 180px;
  color: $color-black;
  background-color: $color-teal;
}

.orange-btn {
  width: 180px;
  color: $color-white;
  background-color: $color-orange;
  min-width: 220px;
  max-width: 270px;
  padding: 0 24px;
}

.text-color-link {
  color: $color-orange;
}

.ad-aside {
  display: flex;
  justify-content: flex-end;

  @media (max-width: $breakpoint-tablet) {
    justify-content: space-evenly;
  }

  img {
    width: 100%;

    @media (max-width: $breakpoint-tablet) {
      max-height: 600px;
      object-fit: cover;
    }

    @media (max-width: $breakpoint-mobile-s) {
      max-width: 300px;
    }
  }
}

.list-item-nested-img {
  left: 10px;
  bottom: 10px;
  position: absolute;
  img {
    width: 40px;
  }
  &.video-img {
    content: '';
    background-image: url('../../images/sections/list-2.svg');
    position: absolute;
    background-repeat: no-repeat;
    background-position: revert;
    width: 40px;
    height: 40px;
    background-size: contain;
  }

  &.podcast-img {
    content: '';
    background-image: url('../../images/sections/list-4.svg');
    position: absolute;
    background-repeat: no-repeat;
    background-position: revert;
    width: 40px;
    height: 40px;
    background-size: contain;
  }

  &.gallery-img {
    content: '';
    background-image: url('../../images/sections/list-1.svg');
    position: absolute;
    background-repeat: no-repeat;
    background-position: revert;
    width: 40px;
    height: 40px;
    background-size: contain;
  }

  &.paid-img {
    content: '';
    background-image: url('../../images/sections/list-3.svg');
    position: absolute;
    background-repeat: no-repeat;
    background-position: revert;
    width: 40px;
    height: 40px;
    background-size: contain;
  }
}

.article-cat {
  color: $color-orange;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  display: block;

  // padding-top: 15px;
  a {
    color: $color-orange;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    display: block;
  }
}

.article-title {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 5px;
  color: $color-black;
  font-weight: bold;
  // max-height: 80px;
  overflow: hidden;

  a {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 5px;
    color: $color-black;
    font-weight: bold;
  }
}

.article-text {
  color: $color-grey-dark;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 5px;
}

.article-img-wrapper {
  width: 100%;
  position: relative;

  a {
    width: 100%;
  }

  img {
    width: 100%;
    // border-radius: 10px;
    // min-height: 210px;
    object-fit: contain;
  }
}

.list-item-left {
  max-width: 140px;

  .list-item-img {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;

    a {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    img {
      background-size: contain;
    }
  }
}

.list-item-right {
}

.article-row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 15px;
  justify-content: flex-start;

  .article-column {
    width: calc(33% - 8px);
    display: flex;
    column-gap: 10px;
    flex-direction: column;
    padding-top: 5px;

    @media (max-width: $breakpoint-mobile) {
      width: calc(100%);
    }

    .article-img-wrapper {
      position: relative;

      .article-nested-img-wrapper {
        position: absolute;
        left: 10px;
        bottom: 10px;
      }
    }
  }

  .article-column-search {
    width: calc(50% - 16px) !important;
  }
}

.article-date {
  color: $color-grey;
  font-size: 14px;
  line-height: 25px;
}

/* the slides */

.slick-slide {
  margin: 0 10px;
}

/* the parent */
.slick-list {
  margin: 0 -10px;
}

.slick-dots {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 99;
  column-gap: 5px;
  bottom: 0 !important;

  li {
    border: none;
    margin: 0;
    width: auto;
    // display: none;
  }

  button {
    display: block;
    width: 5px;
    height: 5px;
    padding: 0;
    border: none;
    background-color: $color-orange;
    opacity: 0.5 !important;
    text-indent: -9999px;
    cursor: pointer;
    border-radius: 10px;
  }

  li.slick-active {
    background: transparent !important;

    button {
      width: 36px;
      opacity: 1 !important;
    }
  }
}

.slick-arrow {
  position: absolute;
  top: 390px;
  width: 42px;
  height: 42px;
  border: none;
  background: $color-white;
  z-index: 300;
  outline: none;
  border: 1px solid $color-black;
  background-repeat: no-repeat;
  border-radius: 80px;
  background-size: 28px 28px;
  cursor: pointer;
  background-position: center;

  &.slick-prev {
    left: 60px;
    background-image: url('../../images/glyphicons/chevron-left.svg');
  }

  &.slick-next {
    right: 60px;
    background-image: url('../../images/glyphicons/chevron-right.svg');
  }
}

.article-list-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 15px;

  @media (max-width: $breakpoint-tablet) {
    row-gap: 30px;
  }

  .article-list-item {
    flex: 1 0 32%;
    display: flex;
    column-gap: 10px;

    @media (max-width: $breakpoint-tablet) {
      flex: 1 0 100%;
      column-gap: 5px;
    }

    .article-img-wrapper {
      max-width: 140px;

      a {
        width: 100%;
      }

      img {
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}

.section-middle-ad {
  padding-bottom: 50px;

  @media (max-width: $breakpoint-mobile) {
    padding-bottom: 30px;
  }

  .middle-ad-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
      max-width: 100%;

      @media (max-width: $breakpoint-tablet) {
        // max-width: 300px;
        // margin: 0 auto;
        object-fit: contain;
        height: auto;
      }
    }
  }
}

.section-big-carousel-news {
  padding-bottom: 50px;
  position: relative;

  h1 {
    display: none;

    @media (max-width: $breakpoint-mobile) {
      display: block;
      text-align: center;
      width: 100%;
      padding: 0 15px;
      font-size: 32px;
      line-height: 40px;
      font-weight: bold;
      margin-bottom: -40px;
    }
  }

  &::after {
    @media (max-width: $breakpoint-mobile) {
      content: '';
      position: absolute;
      background-image: url(../../images/bg/bg14.png);
      background-repeat: no-repeat;
      background-position: 101%;
      background-size: contain;
      width: 100%;
      height: 106px;
      bottom: 100px;
      right: 0;
      z-index: -1;
    }

    @media (max-width: $breakpoint-mobile-s) {
      width: 55px;
    }
  }

  &::before {
    @media (max-width: $breakpoint-mobile) {
      content: '';
      position: absolute;
      background-image: url(../../images/bg/bg-7.png);
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      width: 100%;
      height: 600px;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }

  .container {
    .col-lg-8 {
      @media (max-width: $breakpoint-mobile) {
        padding: 0 !important;
      }
    }
  }

  .wrap {
    position: relative;
    z-index: 100;
    width: 100%;
    height: 100%;
    padding: 0 60px;
    // overflow: hidden;
    // background-image: url('../../images/carousel/bg/bg-1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 67%;

    @media (max-width: $breakpoint-mobile) {
      background-size: 121%;
      padding: 0;
    }

    .big-carousel-bottom {
      padding-bottom: 30px;
      padding-left: 50px;
      padding-right: 20px;

      @media (max-width: $breakpoint-tablet) {
        padding-left: 75px;
      }

      @media (max-width: $breakpoint-mobile) {
        padding-left: 21%;
        margin-top: 20px;
      }

      @media (max-width: $breakpoint-mobile-s) {
        padding-left: 19%;
      }

      @media (max-width: 520px) {
        padding-left: 95px;
      }

      @media (max-width: 480px) {
        padding-left: 75px;
      }

      @media (max-width: 440px) {
        padding-left: 35px;
        padding-right: 35px;
      }

      @media (max-width: 400px) {
        padding-left: 15px;
        padding-right: 15px;
      }

      .article-cat {
        font-size: 18px;
        line-height: 25px;

        a {
          display: inline-block;
          font-size: 18px;
          line-height: 25px;
        }
      }

      .article-text {
        font-size: 18px;
        line-height: 25px;

        @media (max-width: $breakpoint-mobile) {
          width: 85%;
        }
      }

      .article-date {
        font-size: 14px;
      }

      .article-title {
        font-size: 24px;
        line-height: 30px;
        max-height: 60px;

        a {
          font-size: 24px;
          line-height: 30px;
        }

        @media (max-width: $breakpoint-mobile) {
          width: 85%;
        }
      }
    }

    .big-carousel-news-wrapper {
      position: initial;
      z-index: 200;
      max-width: 800px;
      width: 100%;
      padding-bottom: 25px;
      overflow: hidden;

      img {
        display: none;
      }

      // .border-tl {
      //     background-color: transparent;
      //     width: 20px;
      //     height: 20px;
      //     position: absolute;
      //     top: 40px;
      //     position: absolute;
      //     z-index: 8999;
      //     left: 50px;
      //     &::before {
      //         content: '';
      //         position: absolute;
      //         height: 12px;
      //         width: 30px;
      //         border-radius: 0 0 10px 0;
      //         -moz-border-radius: 0 0 10px 0;
      //         -webkit-border-radius: 0 0 10px 0;
      //         -webkit-box-shadow: 10px 0 0 0 #fff;
      //         box-shadow: 10px 0 0 0 #fff;
      //         bottom: 8px;
      //         transform: rotate(180deg);
      //     }
      // }

      @media (max-width: $breakpoint-mobile) {
        padding-bottom: 0;
        height: 350px;
      }

      .item {
        &.slick-slide {
          width: 400px;
          height: 400px;
          transition: transform 1s;
          position: relative;

          a {
            width: 100%;
            height: 100%;
          }

          &.slick-active {
            transform: scale(0.8) translate(250px);
            border-radius: 10px;

            @media (max-width: $breakpoint-mobile) {
              transform: scale(0.6) translate(240px);
            }
          }

          &.slick-center {
            &::after {
              opacity: 0;
            }

            transform: scale(1) translate(0px);
            transition: none !important;
            z-index: 30;

            @media (max-width: $breakpoint-mobile) {
              transform: scale(0.75) translate(0px);
            }

            + .slick-slide {
              transform: scale(0.8) translate(-250px);
              z-index: 10;

              @media (max-width: $breakpoint-mobile) {
                transform: scale(0.6) translate(-240px);
              }

              + .item.slick-slide {
                transform: scale(0.6) translate(50px);
                z-index: 5;

                @media (max-width: $breakpoint-mobile) {
                  // transform: scale(0.6) translate(-920px);
                }
              }
            }
          }

          &::after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            transition: transform 0.4s;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

.section-list-article {
  margin-bottom: 100px;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 50px;
  }

  .article-cat {
    padding-top: 0;
  }

  .list-item-img {
    .article-img-wrapper {
      img {
        min-height: 210px;
      }
    }
  }
}

.section-most-read {
  @media (max-width: $breakpoint-tablet) {
    overflow: hidden;
  }

  .article-wrapper {
    position: relative;

    &:before {
      content: '';
      background-image: url(../../images/bg/bg-4.svg);
      background-repeat: no-repeat;
      background-position: right top;
      background-size: contain;
      transform: rotate(0deg);
      width: 200px;
      height: 200px;
      position: absolute;
      right: 0;
      top: -35px;

      @media (max-width: $breakpoint-mobile) {
        width: 135px;
        height: 57px;
        top: 0;
      }
    }
  }

  margin-bottom: 50px;

  h2 {
    margin-bottom: 30px;
  }

  .article-row {
    position: relative;

    // overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      background-image: url('../../images/bg/bg-3.png');
      background-repeat: no-repeat;
      background-position: left top;
      background-size: contain;
      width: 600px;
      height: 600px;
      left: -15px;
      top: -55px;
      z-index: -1;

      @media (max-width: $breakpoint-tablet) {
        top: -100px;
        left: -100px;
      }
    }

    .article-img-wrapper {
      img {
        min-height: 210px;
        max-height: 210px;

        @media (max-width: $breakpoint-tablet) {
          min-height: 230px;
          max-height: 230px;
          object-fit: cover;
        }
      }
    }

    .article-column {
      margin-bottom: 50px;

      @media (max-width: $breakpoint-tablet) {
        margin-bottom: 40px;
      }

      .article-cat {
        padding-top: 15px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 7px;
        height: 40px;
        overflow: hidden;

        @media (max-width: $breakpoint-tablet) {
          font-size: 18px;
          line-height: 25px;
        }
      }

      .article-title {
        font-weight: bold;

        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          line-height: 30px;
        }
      }

      .article-text {
        @media (max-width: $breakpoint-tablet) {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
}

.page-template {
  .page-template-title {
    max-width: 70%;
  }

  .field-items {
    img {
      max-width: 100%;
      max-height: 600px;
      object-fit: cover;
    }
  }
  .tabs__tab {
    margin-bottom: 5px;
    a {
      padding: 5px 15px;
      border-radius: 80px;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;

      &:hover {
        background-color: #d6fdf2;
        color: #4dd4ac;
      }

      &.active {
        background-color: #d6fdf2;
        color: #4dd4ac;
      }
    }
  }
}

.section-advertorial {
  margin-bottom: 100px;
  overflow: hidden;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 50px;
  }

  .container {
    @media (max-width: $breakpoint-tablet) {
      padding: 0;
    }
  }

  .aside-advertorial {
    .article-list-wrapper {
      .article-list-item {
        .article-list-content {
          @media (max-width: $breakpoint-tablet) {
            display: block !important;
          }
        }

        .article-title {
          max-height: 80px;
          overflow: hidden;
        }
      }
    }
  }

  .section-advertorial-wrapper {
    padding: 20px 15px 35px;
    background-color: $color-teal-white;

    h2 {
      margin-bottom: 30px;
    }

    .article-list-wrapper {
      position: relative;
      min-width: 0;
      min-height: 0;

      .slick-list {
        @media (max-width: $breakpoint-tablet) {
          padding: 0 10% 0 0 !important;
          margin-right: -15px;
        }
      }

      .article-list-item {
        display: block;
        flex: none;

        .article-list-content {
          flex: 1 0 32%;
          display: flex;
          padding-top: 5px;
          column-gap: 10px;

          .article-img-wrapper {
            img {
              width: 140px;
              object-fit: cover;
            }
          }

          .article-title {
            max-height: 100px;
            overflow: hidden;
          }
        }
      }

      .slick-arrow {
        top: -80px;
        background-color: transparent;
        transition: transform 0.1s;

        &:hover {
          transform: scale(1.04);
        }

        &.slick-next {
          left: inherit;
          right: 30px;

          @media (max-width: $breakpoint-tablet) {
            right: 0;
          }
        }

        &.slick-prev {
          @media (max-width: $breakpoint-tablet) {
            right: 55px;
          }

          left: inherit;
          right: 85px;
        }
      }
    }
  }
}

.curious-and-calc-wrapper {
  .ad-aside {
    position: sticky;
    top: 125px;
    z-index: 998;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 30px;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    overflow: hidden;
  }
}

.section-curious {
  margin-bottom: 65px;

  @media (max-width: $breakpoint-mobile) {
    margin: inherit;
    overflow: hidden;
  }

  .row {
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column-reverse !important;
    }

    .col-lg-4 {
      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 50px;
      }
    }
  }

  h2 {
    margin-bottom: 30px;
  }

  .article-list-wrapper {
    @media (max-width: $breakpoint-mobile) {
      column-gap: 23px;
      row-gap: 0;
    }

    &::before {
      @media (max-width: $breakpoint-mobile) {
        content: '';
        position: absolute;
        background-image: url(../../images/bg/bg-3.png);
        background-repeat: no-repeat;
        background-position: left top;
        background-size: contain;
        width: 600px;
        height: 600px;
        left: -100px;
        top: 100px;
        z-index: -1;
      }
    }

    .article-list-item {
      margin-bottom: 35px;

      .article-title {
        @media (max-width: $breakpoint-tablet) {
          font-size: 18px;
        }
      }

      .list-item-left {
        @media (max-width: $breakpoint-tablet) {
          max-width: inherit;
        }

        img {
          @media (max-width: $breakpoint-tablet) {
            width: 100%;
            object-fit: cover;
          }
        }
      }

      @media (max-width: $breakpoint-tablet) {
        margin-bottom: 30px;
        flex-direction: column;
        flex: 1 0 35%;
      }
    }
  }
}

.section-calc {
  margin-bottom: 100px;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 20px;
  }

  .calc-wrapper {
    position: relative;

    &:before {
      content: '';
      background-image: url(../../images/bg/bg-5.svg);
      background-repeat: no-repeat;
      background-position: right top;
      background-size: inherit;
      transform: rotate(0deg);
      width: 200px;
      height: 200px;
      position: absolute;
      right: 0;
      top: -35px;

      @media (max-width: $breakpoint-tablet) {
        display: none;
      }
    }

    .calc-item {
      @media (max-width: $breakpoint-tablet) {
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 23px;
        box-shadow: none;
        min-height: auto;
        padding: 0;
        margin-bottom: 50px;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 15px 18px;
      border-radius: 10px;
      min-height: 204px;
      justify-content: space-between;
      box-shadow: 0px 9px 80px rgba(0, 0, 0, 0.07),
        0px 2.01027px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 0.598509px 5.32008px rgba(0, 0, 0, 0.0282725);

      img {
        margin-bottom: 17px;
        max-height: 89px;

        @media (max-width: $breakpoint-tablet) {
          margin-bottom: 0;
        }
      }

      p {
        font-size: 18px;
        text-align: center;
        font-family: 'SourceSansBold' !important;

        @media (max-width: $breakpoint-tablet) {
          text-align: left;
        }
      }
    }

    h2 {
      margin-bottom: 30px;
    }
  }
}

.section-by-years {
  margin-bottom: 100px;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 50px;
    overflow: hidden;
  }

  h2 {
    margin-bottom: 30px;
  }

  .article-row {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background-image: url('../../images/bg/bg-6.png');
      background-repeat: no-repeat;
      background-position: left top;
      background-size: auto;
      width: 600px;
      height: 600px;
      left: -15px;
      top: -35px;
      z-index: -1;
    }

    .article-column {
      @media (max-width: $breakpoint-tablet) {
        margin-bottom: 30px;
      }

      .article-img-wrapper {
        img {
          min-height: 210px;
          object-fit: cover;

          @media (max-width: $breakpoint-tablet) {
            min-height: 230px;
            max-height: 230px;
            object-fit: cover;
          }
        }

        @media (max-width: $breakpoint-tablet) {
          margin-bottom: 10px;
        }
      }

      .article-text {
        @media (max-width: $breakpoint-tablet) {
          font-size: 16px;
        }
      }

      .article-cat {
        padding-top: 15px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 7px;
        height: 40px;
        overflow: hidden;

        @media (max-width: $breakpoint-tablet) {
          font-size: 18px;
        }
      }

      .article-title {
        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.section-by-podcast {
  margin-bottom: 100px;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 80px;
  }

  h2 {
    margin-bottom: 30px;
  }

  .article-cat {
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 7px;
    height: 40px;
    overflow: hidden;
  }

  .article-wrapper {
    img {
      // min-height: 210px;
      object-fit: cover;

      @media (max-width: $breakpoint-tablet) {
        min-height: 230px;
        max-height: 230px;
        object-fit: cover;
      }
    }

    position: relative;

    &:before {
      content: '';
      background-image: url(../../images/bg/bg-8.svg);
      background-repeat: no-repeat;
      background-position: right top;
      background-size: inherit;
      transform: rotate(0deg);
      width: 200px;
      height: 200px;
      position: absolute;
      right: 0;
      top: -35px;
      z-index: 9;

      @media (max-width: $breakpoint-tablet) {
        width: 100px;
        height: 100px;
        background-size: contain;
        right: -15px;
        background-position-x: 20px;
      }
    }

    .slick-dots {
      bottom: -30px !important;
    }

    .article-row {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background-image: url('../../images/bg/bg-7.png');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: auto;
        width: 100%;
        height: 600px;
        left: 0;
        right: 0;
        top: -33px;
        z-index: -1;

        @media (max-width: $breakpoint-tablet) {
          width: 190px;
          height: 160px;
          background-size: contain;
          left: -15px;
          background-position-x: -75px;
        }
      }

      .article-column {
        @media (max-width: $breakpoint-tablet) {
          column-gap: 15px;
          flex-direction: row;
          display: inline-flex;
        }

        .article-cat {
          @media (max-width: $breakpoint-tablet) {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 10px;
          }
        }

        .article-title {
          @media (max-width: $breakpoint-tablet) {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 10px;
          }
        }

        .article-img-wrapper {
          a {
            @media (max-width: $breakpoint-tablet) {
              display: flex;
              height: 100%;
            }
            img {
              @media (max-width: $breakpoint-tablet) {
                object-fit: cover;
                border-radius: 10px;
                width: 140px;
                // height: 140px;
                // max-height: 140px;
                min-height: 140px;
              }
            }
          }

          .list-item-nested-img {
            img {
              @media (max-width: $breakpoint-tablet) {
                object-fit: cover;
                width: 40px;
                height: 40px;
                max-height: 40px;
                min-height: 40px;
              }
            }
          }

          @media (max-width: $breakpoint-tablet) {
            max-width: 140px;
          }
        }
      }
    }
  }
}

.section-the-day {
  margin-bottom: 100px;

  .article-column {
    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 50px;
    }

    .day-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 20px;
    }

    .day-img {
      border-radius: 10px;
      width: 100%;
      height: auto;
    }

    .day-info {
      font-size: 16px;
      line-height: 22px;
      display: block;

      @media (max-width: $breakpoint-tablet) {
        font-size: 18px;
      }
    }

    .picture-day {
      .day-info {
        color: $color-black;
      }

      .day-img {
        margin-bottom: 10px;

        @media (max-width: $breakpoint-tablet) {
          object-fit: cover;
          max-height: 230px;
          min-height: 230px;
        }
      }

      .img-link {
        display: inline;
      }
    }

    .quote-day {
      @media (max-width: $breakpoint-tablet) {
        &::before {
          content: '';
          position: absolute;
          background-image: url('../../images/bg/for-the-day-bg.png');
          background-repeat: no-repeat;
          background-position: left top;
          background-size: cover;
          width: 220px;
          height: 100%;
          right: -15px;
          top: -35px;
          z-index: -1;
        }
      }

      .day-img {
        margin-bottom: 20px;
        height: 56px;
        width: 56px;
      }

      .day-info {
        color: $color-grey;
        text-align: right;

        @media (max-width: $breakpoint-tablet) {
          font-size: 20px;
        }
      }

      .day-text {
        color: $color-black;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 15px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 20px;
        }
      }
    }

    .horoscope-day {
      // margin-bottom: 100px;
      @media (max-width: $breakpoint-tablet) {
        margin-bottom: 0;
      }

      .horoscope-wrapper {
        padding: 0 20px;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        -moz-column-gap: 15px;
        column-gap: 15px;
        justify-content: space-between;

        .horoscope-cell {
          flex: 0 0 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 20%;
          justify-content: center;

          @media (max-width: $breakpoint-tablet) {
            margin-bottom: 30px;
          }

          img {
            width: 100%;
            object-fit: none;
            height: -webkit-fill-available;
            max-height: 50%;

            @media (max-width: $breakpoint-tablet) {
              object-fit: contain;
              height: 35px;
              margin-bottom: 10px;
            }
          }

          .horoscope-title {
            color: $color-orange;
            font-size: 12px;
            line-height: 17px;

            @media (max-width: $breakpoint-tablet) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.section-partner-news {
  margin-bottom: 100px;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 50px;
  }

  .partner-news-wrapper {
    .article-list-wrapper {
      @media (max-width: $breakpoint-tablet) {
        row-gap: 0;
      }
    }

    .article-column {
      border: 1px solid $color-orange;
      border-radius: 10px;
      padding: 20px 15px;
      background-color: $color-white;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: row;
        padding: 20px 15px 0 15px;
        margin-bottom: 20px;
        width: 100%;
      }

      .article-title {
        @media (max-width: $breakpoint-tablet) {
          font-size: 18px;
        }
      }

      .top-logo {
        margin-bottom: 20px;
        min-height: 45px;
        display: flex;
        align-items: flex-end;

        @media (max-width: $breakpoint-tablet) {
          margin-bottom: 30px;
        }
      }

      .article-list-item {
        margin-bottom: 20px;

        @media (max-width: $breakpoint-tablet) {
          column-gap: 15px;
        }

        img {
          @media (max-width: $breakpoint-tablet) {
            width: 140px;
            height: 140px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.page-item {
  margin-right: 5px;
  color: $color-orange;

  &:last-child {
    .page-link {
      border-radius: 2px;
      background-color: $color-orange;
    }
  }

  .page-link {
    border: 1px solid $color-orange;
    padding: 0 10px;
    color: $color-orange;
    background-color: $color-white;
    height: 32px;
    border-radius: 2px;
    display: flex;
    align-items: center;

    &:hover {
      color: $color-white;
      background-color: $color-orange;
      border-color: $color-orange;
    }
  }
}

.section-category-main {
  margin-bottom: 100px;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 50px;
  }

  .article-row {
    .article-column {
      .article-img-wrapper {
        img {
          min-height: 230px;
          max-height: 230px;
          object-fit: cover;
        }
      }
    }
  }

  .article-cat {
    padding-top: 10px;
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;

    a {
      display: inline-block;
      width: auto;
      margin: 0;
    }
  }

  h2 {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 30px;
  }

  .article-label {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;

    a,
    span,
    .label-links {
      background-color: #fff0e9;
      color: $color-orange;
      padding: 5px 15px;
      border-radius: 80px;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      margin: 0;
    }
  }

  .article-wrapper {
    position: relative;

    &.search-wrapper {
      margin-top: 20px;

      .article-title {
        margin-top: 10px;
      }

      &::before {
        top: -105px !important;
        right: 0;
      }
    }

    &::before {
      content: '';
      background-image: url(../../images/bg/bg-4.svg);
      background-repeat: no-repeat;
      background-position: right top;
      background-size: inherit;
      transform: rotate(0deg);
      width: 200px;
      height: 200px;
      position: absolute;
      right: 33%;
      top: -35px;

      @media (max-width: $breakpoint-tablet) {
        width: 135px;
        height: 57px;
        top: 0;
        background-position: right top;
        background-size: contain;
        right: 15px;
      }
    }

    .article-row {
      &::before {
        content: '';
        position: absolute;
        background-image: url(../../images/bg/bg-3.png);
        background-repeat: no-repeat;
        background-position: left top;
        background-size: auto;
        width: 600px;
        height: 600px;
        left: -15px;
        top: -55px;
        z-index: -1;

        @media (max-width: $breakpoint-tablet) {
          width: 100%;
        }
      }

      .article-column {
        margin-bottom: 35px;
      }
    }
  }
}

.section-article-main {
  .tabs {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    list-style-type: none;
  }

  li.tabs__tab {
    a {
      padding: 5px 15px;
      border-radius: 80px;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;

      &:hover {
        background-color: #d6fdf2;
        color: #4dd4ac;
      }

      &.active {
        background-color: #d6fdf2;
        color: #4dd4ac;
      }
    }
  }
}

.section-article-main,
.page-template {
  margin-bottom: 50px;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 0;
  }

  .atm-text,
  .atm-text p {
    // color: #777777 !important;
    // font-size: 14px !important;
    // line-height: 25px !important;
    // margin: 0 55px;
    // margin-bottom: 55px !important;
    // display: block;
    // text-align: left;
    @media (max-width: $breakpoint-mobile) {
      text-align: left;
      margin: 0;
    }
  }

  .section-gallery-banners {
    .field-video {
      width: 100%;
      padding: 25px 0;

      iframe {
        width: 100%;
      }
    }
  }

  .article-more-carousel {
    .article-img-wrapper {
      a {
        border-radius: 5px;
        overflow: hidden;
      }
    }
  }

  .col-md-8,
  .col-lg-8 {
    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 17px;
    }

    h6 {
      font-size: 16px;
    }

    h2,
    h3,
    h4 {
      color: $color-orange;
    }

    h5,
    h6 {
      color: $color-teal;
    }
  }

  .article-top-title {
    font-weight: bold;
    line-height: 37px;
    font-size: 28px;
  }
  .article-label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    @media (max-width: 480px) {
      flex-direction: column;
    }
    .article-label {
      padding-top: 10px;
    }
  }
  .article-label {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    a,
    span,
    .label-links {
      background-color: #fff0e9;
      color: $color-orange;
      padding: 5px 15px;
      border-radius: 80px;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
    }
  }
  .article-label-spons {
    flex: 1 0 auto;
    @media (max-width: $breakpoint-tablet) {
      flex: 1 1 auto;
    }
    @media (max-width: 480px) {
      order: -1;
      align-items: flex-start;
    }
    .article-main {
      padding-top: 10px;
    }
    .label-spons {
      background: $color-white;
      color: $color-black;
    }
    img {
      max-height: 50px;
      object-fit: contain;
    }
  }
  .article-main {
    .article-main-text,
    p:not(:last-child),
    .amt-bold {
      color: $color-black;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 25px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 20px;
      }
    }

    strong,
    .amt-bold {
      font-weight: bold;
    }

    .article-top {
      .article-top-title {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        object-fit: cover;
        height: auto;
      }

      .article-top-subtitle,
      .article-top-subtitle-color {
        margin-bottom: 10px;
        font-weight: bold;
        color: $color-grey;
        font-size: 20px;
        line-height: 30px;
      }

      .article-top-subtitle-color {
        color: $color-teal;
      }

      .article-top-content {
        .article-top-main {
          display: flex;
          position: relative;
          flex-direction: column;
          margin-bottom: 30px;

          @media (max-width: $breakpoint-tablet) {
            margin-bottom: 0;
          }

          &::after {
            content: '';
            position: absolute;
            background-image: url(../../images/bg/bg-11.png);
            background-repeat: no-repeat;
            background-position: top left;
            background-size: auto;
            width: 100%;
            height: 100%;
            left: -15px;
            top: 25px;
            z-index: -1;
          }

          .atm-img {
            text-align: center;
            width: 100%;
            margin-bottom: 30px;

            img {
              border-radius: 10px;
              max-width: 530px;

              @media (max-width: $breakpoint-tablet) {
                width: 100%;
              }

              @media (max-width: $breakpoint-mobile-s) {
                width: 100%;
              }
            }
          }
        }

        .article-top-social {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;

          @media (max-width: $breakpoint-tablet) {
            align-items: flex-start;
            flex-direction: column;
            row-gap: 15px;
          }

          .ats-left {
          }

          .ats-right {
            display: flex;
            column-gap: 19px;
          }
        }
      }
    }

    .article-steps {
      .btm-links {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 50px;
        column-gap: 10px;

        @media (max-width: $breakpoint-tablet) {
          flex-wrap: wrap;
          justify-content: flex-start;
          column-gap: 20px;
        }

        a {
          color: $color-orange;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;

          @media (max-width: $breakpoint-tablet) {
            font-size: 15px;
          }
        }
      }

      &.bg-13 {
        position: relative;

        &::after {
          content: '';
          background-image: url(../../images/bg/bg13.svg);
          background-repeat: no-repeat;
          background-position: right top;
          background-size: inherit;
          transform: rotate(0deg);
          width: 200px;
          height: 200px;
          position: absolute;
          right: 35px;
          top: -25px;

          @media (max-width: $breakpoint-tablet) {
            display: none;
          }
        }
      }

      .article-steps-title {
        font-size: 24px;
        line-height: 33px;
        color: $color-orange;
        margin-bottom: 15px;
        font-weight: bold;
      }


    }
    .article-steps-link {
      margin: 0 50px;
      margin-bottom: 25px;

      @media (max-width: $breakpoint-tablet) {
        margin: 20px 0;
      }

      .article-steps-link-main {
        border: 1px solid $color-black;
        border-radius: 10px;
        padding: 12px 20px 12px 15px;
        display: flex;
        justify-content: space-between;
        column-gap: 30px;
        align-items: center;

        .article-main-text {
          margin-bottom: 0;
        }

        .aslm-title {
          color: $color-orange;
          font-size: 14px;
          line-height: 20px;
          font-weight: bold;
        }

        .aslm-text {
          color: $color-black;
          font-size: 18px;
          line-height: 25px;
          font-weight: bold;

          @media (max-width: $breakpoint-tablet) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .article-aside {
    & > div {
      margin-bottom: 70px;
      position: relative;

      h2 {
        margin-bottom: 25px;
      }
    }

    .aside-new {
      .article-title {
        max-height: 80px;
        overflow: hidden;
      }
    }

    .ad-aside:first-of-type {
      margin-bottom: 50px;
    }

    .section-advertorial {
      @media (max-width: $breakpoint-tablet) {
        margin: 0 -15px;
        margin-bottom: 50px;
      }
    }

    .aside-new {
      &::before {
        content: '';
        background-image: url(../../images/bg/bg-5.svg);
        background-repeat: no-repeat;
        background-position: right top;
        transform: rotate(0deg);
        width: 200px;
        height: 200px;
        position: absolute;
        right: 0;
        top: -35px;
        background-size: 100px auto;
        z-index: -1;
      }

      &::after {
        content: '';
        position: absolute;
        background-image: url(../../images/bg/bg-9.png);
        background-repeat: no-repeat;
        background-position: top right;
        background-size: auto;
        width: 100%;
        height: 100%;
        right: 0;
        top: 25px;
        z-index: -1;
      }

      .article-list-item {
        .article-cat {
          padding-top: 0;
        }
      }

      .article-list-wrapper {
        .article-list-item {
          margin-bottom: 35px;

          @media (max-width: $breakpoint-tablet) {
            margin: 0;
          }

          .list-item-left {
            max-width: 100px;

            .list-item-img {
              img {
                // max-width: 100px;
              }
            }
          }
        }
      }
    }

    .aside-calc {
      .aside-calc-item {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-bottom: 30px;

        img {
          height: 80px;
        }

        .aside-calc-text {
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
        }
      }
    }

    .aside-advertorial {
      &::after {
        content: '';
        position: absolute;
        background-image: url(../../images/bg/bg-10.png);
        background-repeat: no-repeat;
        background-position: top left;
        background-size: auto;
        width: 100%;
        height: 100%;
        left: 0;
        top: 25px;
        z-index: -1;
      }

      .article-list-wrapper {
        .article-list-item {
          .article-list-item {
            margin-bottom: 0;
          }

          img {
            max-width: 100px;
            object-fit: cover;
          }

          margin-bottom: 35px;
          padding-left: 15px;

          @media (max-width: $breakpoint-tablet) {
            margin: 0;
            padding-left: 0;
            display: inline-flex;
          }

          .list-item-left {
            max-width: 100px;

            .list-item-img {
              border-radius: 10px;

              img {
                max-width: 100px;
              }
            }
          }
        }
      }
    }
  }
}

.article-steps-carousel {
  // margin: 0 55px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  max-width: 600px;
  // background-color: #FFE7D5;
  border-radius: 10px;
  margin: 0 au;
  margin-bottom: 5px;

  &:hover {
    .slick-arrow {
      display: block !important;
    }
  }

  .slider__counter {
    position: absolute;
    top: 5px;
    left: 10px;
    z-index: 1;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    mix-blend-mode: difference;
    pointer-events: none;
  }

  @media (max-width: $breakpoint-tablet) {
    margin: 0;
  }

  .slick-arrow {
    top: 180px;

    // display: none !important;
    @media (max-width: $breakpoint-tablet) {
      display: block !important;
    }

    @media (max-width: $breakpoint-mobile) {
      top: 120px;
    }
  }

  .slick-dots {
    li {
      &.slick-active {
        button {
          width: 5px;
          background-color: $color-teal;
        }
      }
    }

    button {
      display: block;
      width: 5px;
      height: 5px;
      padding: 0;
      border: none;
      background-color: $color-white;
      opacity: 1 !important;
      text-indent: -9999px;
      cursor: pointer;
      border-radius: 10px;
      margin-bottom: 20px;
    }
  }

  .slick-dots {
    bottom: inherit !important;
    top: 15px !important;
  }

  .slick-arrow {
    position: absolute;
    width: 42px !important;
    height: 42px !important;
    border: none;
    background: #fff;
    z-index: 300;
    outline: none;
    border: 1px solid #191414 !important;
    background-repeat: no-repeat;
    border-radius: 80px;
    background-size: 28px 28px;
    cursor: pointer;
    background-position: center;
  }

  .slick-prev {
    border: none;
    width: 24px;
    height: 24px;
    left: 0;
    background-image: url(../../images/glyphicons/chevron-left.svg);

    @media (max-width: $breakpoint-mobile) {
      left: 25px;
    }
  }

  .slick-next {
    border: none;
    width: 24px;
    height: 24px;
    right: 0;
    background-image: url(../../images/glyphicons/chevron-right.svg);

    @media (max-width: $breakpoint-mobile) {
      right: 25px;
    }
  }

  .slick-slide {
    margin: 0;
    padding: 0 55px;
    display: inline-grid !important;
    justify-content: space-around;
    position: relative;
    padding-top: 50px;
    border-radius: 10px;
    padding-bottom: 30px;

    @media (max-width: $breakpoint-mobile) {
      padding: 0;
    }

    img {
      width: 100%;
      border-radius: 10px;
      // max-height: 355px;
      // min-height: 355px; */
      background-size: contain;
    }
  }
}

.article-steps-carousel-info {
  span,
  em,
  i {
    font-weight: normal;
    // color: $color-grey;
    // font-size: 14px;
    // line-height: 25px;
    // margin: 0 55px;
    // margin-bottom: 55px;
    // display: block;
  }
}

.article-advice {
  margin: 0 55px;
  padding-bottom: 80px;
  position: relative;

  @media (max-width: $breakpoint-tablet) {
    margin: 0;
  }

  &::before {
    content: '';
    position: absolute;
    background-image: url(../../images/bg/bg12.png);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: auto;
    width: 100%;
    height: 500px;
    left: -55px;
    top: -25px;
    z-index: -1;
  }

  &::after {
    @media (max-width: $breakpoint-tablet) {
      content: '';
      background-image: url(../../images/bg/bg13.svg);
      background-repeat: no-repeat;
      background-position: right top;
      background-size: contain;
      transform: rotate(0deg);
      width: 58px;
      height: 53px;
      position: absolute;
      right: 0;
      top: -25px;
    }
  }

  .article-advice-title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
  }

  .article-advice-text {
    font-style: italic;
    font-size: 18px;
    line-height: 30px;
  }
}

.article-more-carousel {
  margin-right: -10px;
  // margin-bottom: 50px;
  padding-bottom: 50px;
  overflow: hidden;

  @media (max-width: $breakpoint-tablet) {
    margin-right: 0;
  }

  .amc-title {
    margin-bottom: 20px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .article-img-wrapper {
    width: auto;
  }

  .article-more-carousel {
    .slick-list {
      &::after {
        width: 274px;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -75px;
        background: linear-gradient(
          270deg,
          #ffffff 47.46%,
          rgba(255, 255, 255, 0) 103.44%
        );
        height: 100%;

        @media (max-width: $breakpoint-tablet) {
          display: none;
        }
      }

      @media (max-width: $breakpoint-tablet) {
        padding: 0 27% 0 0 !important;
      }
    }

    .slick-dots {
      bottom: -30px;
    }

    .article-column {
      display: flex;
      -moz-column-gap: 10px;
      column-gap: 10px;

      .article-title {
        a {
          max-width: 100%;
        }
      }

      .article-img-wrapper {
        img {
          height: 100px;
          width: 100px;
          object-fit: cover;
        }
      }
    }
  }
}

.article-mid-ad2 {
  text-align: center;

  a {
    width: 100%;
    display: inline-block;
    margin-bottom: 50px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @media (max-width: $breakpoint-mobile-s) {
        max-width: 300px;
      }
    }
  }
}

.article-comment {
  position: relative;
  margin-bottom: 50px;

  &::before {
    content: '';
    background-image: url(../../images/bg/bg-13.svg);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: inherit;
    transform: rotate(0deg);
    width: 200px;
    height: 200px;
    position: absolute;
    left: 0;
    top: -35px;
    z-index: -1;
  }

  .btn {
    margin-bottom: 43px;
  }

  .comment-text-wrapper {
    display: block;

    &.active {
      display: none;
    }
  }

  .comment-text {
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 35px;
  }

  .comment-top {
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .comment-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
    }

    .comment-btn {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 165px;
      border: 1px solid #5e3737;
      border-radius: 80px;
      padding: 11px 30px;
      text-decoration: none;

      @media (max-width: $breakpoint-tablet) {
        padding: 11px 15px;
        width: auto;
        column-gap: 15px;
      }

      .open-text {
        display: block;
      }

      .close-text {
        display: none;
      }

      &.active {
        .open-text {
          display: none;
        }

        .close-text {
          display: block;
        }

        img {
          transform: rotate(180deg);
        }
      }

      span {
        font-size: 18px;
        line-height: 22px;
        font-weight: bold;
      }
    }
  }

  .comment-field {
    display: none;

    textarea {
      border: 1px solid $color-black;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 15px;
      min-height: 130px;
      width: 100%;
      resize: vertical;

      &::placeholder {
        color: $color-grey;
        font-size: 16px;
        line-height: 22px;
      }
    }

    p {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 19px;
      color: $color-grey;
    }
  }

  .comment-user {
    margin-bottom: 30px;
    display: none;
    &:not(.comment-user ~ .comment-user) {
      display: block;
    }

    .comment-user-top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .comment-user-top-user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        margin-bottom: 10px;
        .comment-user-image {
          img {
            border-radius: 50%;
          }
        }
        .cutu-img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          object-fit: cover;
        }

        .cutu-name {
          font-weight: bold;
          font-size: 20px;
          line-height: 19px;
        }
      }

      .comment-user-top-date {
        color: $color-grey-white;
        font-size: 16px;
        line-height: 25px;
      }
    }

    .comment-user-main {
      p {
        color: $color-black;
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 15px;
      }
    }

    .comment-user-bottom {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 30px;

      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 10px;
        font-size: 16px;
        line-height: 25px;
        color: $color-grey-white;

        img {
          cursor: pointer;
        }
      }

      .cub-text {
        .cub-text-btn {
          cursor: pointer;
        }
      }
    }
  }

  .comment-user-response {
    margin-bottom: 15px;
    display: none;

    .comment-user-response-top {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 10px;
      margin-bottom: 10px;

      .curt-profile {
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .curt-field {
        width: 100%;

        input {
          color: $color-grey-white;
          width: 100%;
          font-size: 14px;
          line-height: 17px;
          padding: 9px;
          border: 0;
          border-bottom: 1px solid $color-black;

          &:focus,
          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    .comment-user-response-bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 15px;

      button {
        color: $color-grey-white;
        width: 110px;
        height: 35px;
        cursor: pointer;
        font-weight: bold;
        margin: inherit;

        &.transperant-btn {
          background-color: transparent;
          border: none;
        }

        &.teal-btn {
          color: white;
        }
      }
    }
  }

  .comment-response {
    margin-bottom: 30px;
    color: $color-teal;
    cursor: pointer;

    .comment-response-btn {
      cursor: pointer;
    }
  }

  .comment-see-all {
    font-size: 16px;
    border-bottom: 1px solid $color-black;
    cursor: pointer;
    display: inline-block;
  }
}

.article-top-main {
  a {
    text-decoration: underline;
  }
}

.section-news {
  .article-label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $breakpoint-tablet) {
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;
    }

    .article-label-spons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 20px;

      .label-spons {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }

  .article-steps {
    .single-img {
      margin: 0 70px;
      margin-bottom: 60px;

      .atm-text {
        color: #777777;
        font-size: 14px;
        line-height: 25px;
        margin: 0 55px;
        margin-bottom: 55px;
        display: block;
        text-align: left;

        @media (max-width: $breakpoint-mobile) {
          text-align: left;
          margin: 0;
        }
      }

      @media (max-width: $breakpoint-mobile) {
        margin: 0;
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }

  .article-main {
    .article-top-main {
      a {
        text-decoration: underline;
      }

      &::after {
        height: 500px !important;
      }
    }

    .atm-text,
    .atm-img {
      margin-bottom: 0 !important;
    }

    .article-top {
      margin-bottom: 30px;
    }

    .article-top-subtitle {
      margin-bottom: 15px !important;
    }

    .atm-text {
      a {
        text-decoration: underline;
      }

      ul,
      li {
        list-style-type: disc;
      }

      ol {
        padding: 0;
      }
    }
  }
}

.section-gallery-links {
  margin-bottom: 70px;
  overflow: hidden;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 50px;
  }

  .gallery-list-item {
    & > .row {
      margin-right: 5px;
      margin-left: 5px;

      .col-lg-12 {
        padding: 0;
      }
    }
  }

  .gallery-item-wrapper {
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
    display: inline-flex;

    .hover-scale {
      width: 100%;
    }
  }

  .col-lg-6 {
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
  }

  .gallery-item-wrapper {
    a:first-of-type {
      width: 100%;
      position: initial;

      &:hover {
        &::before {
          opacity: 0;
        }
      }

      &::before {
        content: '';
        border-radius: 10px;
        position: absolute;
        left: 5px;
        right: 5px;
        height: 100%;
        background-color: $color-black;
        opacity: 0.15;
      }
    }

    .gallery-item-blur {
      // border-radius: 10px;
      // position: absolute;
      // left: 5px;
      // right: 5px;
      // height: 100%;
      // background-color: $color-black;
      // opacity: 0.15;
    }
  }

  .col-12.gallery-item-wrapper {
    img {
      height: 273px;

      @media (max-width: $breakpoint-tablet) {
        height: 195;
      }
    }
  }

  .col-6.gallery-item-wrapper {
    img {
      height: 186px;

      @media (max-width: $breakpoint-tablet) {
        height: 135;
      }
    }
  }

  h2 {
    margin-bottom: 20px;
  }

  .section-gallery-wrapper {
    overflow-x: hidden;
  }

  .gallery-list-wrapper {
    .slick-arrow {
      top: -65px;
      background-color: transparent;
      transition: transform 0.1s;

      &:hover {
        transform: scale(1.04);
      }
    }

    .slick-next {
      right: 15px;
    }

    .slick-prev {
      left: inherit;
      right: 70px !important;
    }

    .slick-slide {
      img {
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }

    .gallery-list-item {
      .gallery-text {
        position: absolute;
        bottom: 20px;
        left: 15px;
        color: $color-white;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.log-popup {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 9999999999999999999999999999;
  overflow: auto;

  // pointer-events: none;
  &:hover {
    // background: rgba(0, 0, 0, 0.5);
  }

  .ps-title {
    margin-bottom: 35px;
    font-weight: bold;

    @media (max-width: $breakpoint-mobile) {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 10px;
    }
  }

  .popup-wrapper {
    width: 642px;
    pointer-events: auto;
    overflow: auto;
    padding: 40px 76px 28px 76px;
    background: white;
    color: black;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    box-sizing: border-box;
    border-radius: 10px;
    min-height: 620px;

    @media (max-width: $breakpoint-mobile) {
      width: 375px;
      padding: 50px 15px;
      min-height: auto;
    }

    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 20px;
      display: block;
      cursor: pointer;
    }
  }

  .popup-success {
    .ps-img {
      margin-bottom: 30px;
      max-width: 216px;
      object-fit: cover;
    }

    .ps-text {
      margin-bottom: 25px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .popup-tabs {
    // padding: 20px 0;
    .tab.tab-active {
      display: block;
    }

    .tab-a {
      color: $color-grey-white;
      font-size: 16px;
      line-height: 20px;
      border-radius: 10px;
      padding: 10px;
      min-width: 115px;
      display: inline-block;

      @media (max-width: $breakpoint-mobile) {
        min-width: 85px;
        font-size: 15px;
        line-height: 20px;
        padding: 8px;
      }

      &.active-a {
        font-weight: bold;
        color: $color-black;
        background-color: $color-teal-white;
      }
    }

    .tab {
      display: none;

      .orange-btn {
        width: auto;
        max-width: 395px;
      }
    }

    & > ul {
      display: flex;
      justify-content: space-between;
      margin-left: 0;
      margin-bottom: 30px;

      li {
        list-style-type: none;
      }
    }
  }
}

.bounce {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  color: white;
  animation-name: unset;
  &:hover {
    cursor: pointer;
    animation-name: bounce;
  }
}

.input-wrapper {
  margin-bottom: 25px;

  .input-container {
    position: relative;
    margin-bottom: 10px;

    &:hover input {
      &::placeholder {
        color: $color-teal;
      }

      color: $color-teal;

      & + img {
        filter: invert(73%) sepia(41%) saturate(550%) hue-rotate(88deg)
          brightness(166%) contrast(87%);
      }
    }
  }

  input {
    width: 100%;
    border: 1px solid $color-black;
    padding: 10px 15px;
    outline: none;
    height: 45px;
    border-radius: 10px;
    font-size: 14px;

    &:focus {
      &::placeholder {
        color: $color-teal;
      }

      color: $color-teal;

      & + img {
        filter: invert(73%) sepia(41%) saturate(550%) hue-rotate(88deg)
          brightness(166%) contrast(87%);
      }
    }
  }

  img {
    position: absolute;
    top: 10px;
    right: 15px;
  }

  .input-text {
    display: block;
    text-align: left;
    font-size: 14px;
  }
}

.g-recaptcha {
  margin-bottom: 25px;
}

.checkbox-wrapper {
  margin-bottom: 25px;

  .checkbox-container {
    position: relative;

    .checkbox-label {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 16px;
      margin-bottom: 12px;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      color: $color-grey-white;

      &::before {
        position: absolute;
        content: '';
        left: 10px;
        top: 10px;
        width: 0;
        height: 0;
        border-radius: 3px;
        border: 1px solid $color-grey-white;
        transform: scale(0);
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;

        &:checked ~ .checkbox-custom {
          background-color: $color-white;
          transform: rotate(0deg) scale(1);
          opacity: 1;
          border: 1px solid $color-teal;
        }

        &:checked ~ .checkbox-custom::after {
          transform: rotate(45deg) scale(1);
          opacity: 1;
          left: 6.5px;
          top: 2px;
          width: 6px;
          height: 11px;
          border: solid $color-teal;
          border-width: 0 2px 2px 0;
          background-color: transparent;
          border-radius: 0;
        }
      }

      .checkbox-title {
        margin-left: 30px;
        width: 100%;
      }

      .checkbox-custom {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 4px;
        background-color: transparent;
        transition: all 0.3s ease-out;
        border: 1px solid $color-grey-white;

        &::after {
          position: absolute;
          content: '';
          left: 12px;
          top: 12px;
          height: 0;
          width: 0;
          border-radius: 3px;
          border: solid $color-grey-white;
          border-width: 0 2px 2px 0;
          transform: rotate(0deg) scale(0);
          opacity: 1;
          transition: all 0.3s ease-out;
        }
      }
    }
  }
}

.section-calculator {
  margin-bottom: 150px;
  margin-top: 20px;

  @media (max-width: $breakpoint-mobile) {
  }

  h2 {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 30px;
  }

  .calc-wrapper {
    position: relative;

    &:before {
      content: '';
      background-image: url(../../images/bg/bg-5.svg);
      background-repeat: no-repeat;
      background-position: right top;
      background-size: inherit;
      transform: rotate(0deg);
      width: 200px;
      height: 200px;
      position: absolute;
      right: 0;
      top: -105px;

      @media (max-width: $breakpoint-tablet) {
        display: none;
      }
    }

    .calc-item {
      @media (max-width: $breakpoint-tablet) {
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 23px;
        box-shadow: none;
        min-height: auto;
        padding: 0;
        margin-bottom: 30px;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 15px 18px;
      border-radius: 10px;
      min-height: 204px;
      justify-content: space-between;
      box-shadow: 0px 9px 80px rgba(0, 0, 0, 0.07),
        0px 2.01027px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 0.598509px 5.32008px rgba(0, 0, 0, 0.0282725);

      img {
        margin-bottom: 17px;
        max-height: 89px;

        @media (max-width: $breakpoint-tablet) {
          margin-bottom: 0;
        }
      }

      p {
        font-size: 18px;
        text-align: center;
        font-family: 'SourceSansBold' !important;

        @media (max-width: $breakpoint-tablet) {
          text-align: left;
        }
      }
    }

    h1,
    h2 {
      margin-bottom: 30px;
    }
  }

  .calc-error-text,
  .calc-error-text-megele {
    color: red;
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
  }

  .tab {
    display: none;
  }

  .tab-active {
    display: block;
  }

  .calc-tab-menu {
    margin-bottom: 0;
    margin-bottom: 30px;

    ul {
      display: flex;
      justify-content: space-between;
      column-gap: 25px;

      @media (max-width: $breakpoint-mobile) {
        column-gap: 5px;
      }

      li {
        flex: 1 0 47%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        a {
          font-size: 20px;
          line-height: 25px;
          height: 50px;
          align-items: center;
          font-weight: bold;
          background-color: $color-white;
          display: flex;
          padding: 15px 40px;
          border-radius: 10px;
          color: #666666;
          width: 100%;
          justify-content: center;

          @media (max-width: $breakpoint-mobile) {
            padding: 15px;
            font-size: 16px;
          }

          @media (max-width: $breakpoint-mobile-s) {
            padding: 0;
          }

          &.active-a {
            border: none;
            color: $color-orange;
            background-color: #fff0e9;
          }
        }
      }
    }
  }

  .submit-btn-calc,
  .submit-btn-calc-megele {
    margin-top: 30px;
    font-weight: normal;
  }

  .section-calc-main-wrapper {
    position: relative;

    .all-tabs {
      border-radius: 10px;
      padding: 40px 50px;
      box-shadow: 0 0 6px rgb(0 0 0 / 10%);

      @media (max-width: $breakpoint-tablet) {
        padding: 10px;
        padding-bottom: 32px;
      }
    }

    .heavy-title {
      margin-top: 40px;
      font-size: 28px;
      text-align: center;
      font-weight: bold;
    }

    .final-date-result {
      margin: 0 auto;
      font-size: 36px;
      font-family: 'SourceSansBold' !important;
      color: $color-orange;
      font-weight: bold;
      text-align: center;
      margin-top: 5px;
      margin-bottom: 20px;
    }

    .final-week-result,
    .final-week-result-megele {
      margin: 0 auto;
      text-align: center;
      margin-bottom: 25px;
      font-size: 18px;
      font-weight: bold;
    }

    .reset-calc-btn {
      margin: 0 auto;
      text-align: center;
      width: 220px;
      height: 48px;
      font-size: 18px;
      border: 1px solid black;
      border-radius: 80px;
      color: $color-black;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }

    .result-calc-wrapper,
    .result-calc-wrapper-megele {
      position: absolute;
      left: 0;
      right: 0;
      height: auto;
      border-radius: 10px;
      background-color: $color-white;
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
      z-index: 12;
      padding-bottom: 35px;
      display: none;
    }

    .result-title {
      width: 155px;
      height: 46px;
      margin: 0 auto;
      padding: 13px;
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
      border-radius: 0 0 5px 5px;
      background-color: #fff;
      padding-top: 12px;
      text-align: center;
      font-size: 21px;
    }

    .stork {
      display: block;
      margin: 0 auto;
      margin-bottom: 25px;
    }
  }

  .form-wrapper {
    .label {
      margin-bottom: 10px;
      font-size: 18px;
      color: $color-black;
      margin-bottom: 15px;
      font-weight: bold;
    }

    .form-group-cycle {
      width: 100%;
      display: inline;
      margin: 0 !important;

      select {
        width: 100%;
      }
    }

    .form-group {
      margin-bottom: 0;
      position: relative;
      flex-grow: 3;
      margin-right: 25px;
      width: min-content;

      @media (max-width: $breakpoint-mobile) {
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        &::after {
          transform: rotate(180deg);
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 15px;
        height: 15px;
        width: 15px;
        margin-top: -7px;
        pointer-events: none;
        background: url(../../images/glyphicons/calc-arrow-select.svg) no-repeat
          center/100%;

        @media (max-width: $breakpoint-mobile-s) {
          width: 10px;
          height: 5px;
          right: 10px;
          margin-top: -3px;
        }
      }
    }
    select {
      padding-left: 15px;
      padding-right: 15px;
      appearance: none;
      text-indent: 1px;
      cursor: pointer;
      height: 45px;
      width: 100%;
      border-radius: 10px;
      border: 1px solid #191414;
      font-size: 14px;
      color: #666666;

      &:focus-visible {
        outline: none;
      }
    }

    .date-time-wrapper {
      .form-group-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        // justify-content: space-between;
      }
    }
  }
}

.form-item {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'] {
    width: 50%;
    border: 1px solid $color-black;
    padding: 10px 15px;
    outline: none;
    height: 45px;
    border-radius: 10px;
    font-size: 18px;
    margin-bottom: 50px;
    margin-top: 25px;

    &:focus {
      &::placeholder {
        color: $color-black;
      }

      color: $color-black;

      & + img {
        filter: invert(73%) sepia(41%) saturate(550%) hue-rotate(88deg)
          brightness(166%) contrast(87%);
      }
    }
    &#edit-captcha-response {
      margin-left: 25%;
      text-align: center;
    }
  }
  &.form-type-file {
    label {
      border: 1px solid #ccc;
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;
    }
    input[type='file'] {
      display: none;
    }
  }
}

section.new-calendar {
  h4 {
    margin-bottom: 15px;
  }

  .box-container {
    margin-bottom: 15px;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    background: rgba($color: #ffe918, $alpha: 0.1);

    @media (max-width: $breakpoint-mobile-s) {
      padding: 10px;
    }
  }

  .item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 55px;
    height: 80px;
    margin-right: 5px;
    margin-bottom: 8px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #4dd4ac;
    transition: 200ms all;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: #4dd4ac;
      text-decoration: none;

      .text,
      .number {
        color: #fff;
      }
    }
  }

  .text {
    display: block;
    padding-top: 6px;
    color: #595959;
    font-size: 12px;
    font-weight: 600;
    transition: 200ms all;
  }

  .number {
    color: #4dd4ac;
    font-size: 42px;
    line-height: 42px;
    font-weight: 600;
    transition: 200ms all;
  }
}
#user-profile-form {
  .form-actions {
    display: flex;
    gap: 20px;
  }
  input[type='submit'] {
    padding: 5px 15px;
    border-radius: 80px;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    background-color: #d6fdf2;
    color: #4dd4ac;
    border-width: 0;
    &#edit-cancel {
      background-color: #fff0e9;
      color: #ff6727;
    }
  }
}
#comment-user-image {
  display: grid;
  img {
    border-radius: 50%;
  }
}
