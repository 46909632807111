.article-main {
  .atm-text {
    // p {
    //   margin: 0 !important;
    // }

    a {
      text-decoration: underline;
    }

    ul,
    ol {
      padding-left: 20px;
    }

    ul {
      li {
        list-style-type: disc;
      }
    }

    ol {


      li {
        list-style-type: auto;
      }
    }

    blockquote {
      display: block;
      position: relative;

      p {
        padding: 20px 40px 40px;
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
      }

      &:after {
        content: url('/sites/all/themes/custom/obekti/images/bg/blockquote.png');
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: -1;
      }
      &:before {
        content: url('/sites/all/themes/custom/obekti/images/bg/blockquote-top.png');
        position: absolute;
        bottom: 0px;
        right: 0;
        z-index: -1;
      }
    }
  }
}
