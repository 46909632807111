  @font-face {
    font-family: 'SourceSans';
    src: url(../fonts/SourceSans3-Regular.otf);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
  font-family: 'SourceSansBold';
  src: url(../fonts/SourceSans3-Semibold.otf);
  font-weight: normal;
  font-style: normal;
  font-display: block;
}