.btn-orange {
    background:$color-orange;
    font-size: 16px;
    color: $color-white;
    border: none;
    width: 135px;
    height: 36px;
    cursor: pointer;
    border-radius: 80px;
    display: inline-block;
    text-align: center;
    padding-top: 7px;
}
