@-webkit-keyframes fadeInLeftSide {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeftSide {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeftSide {
  -webkit-animation-name: fadeInLeftSide;
  animation-name: fadeInLeftSide;
}


@-webkit-keyframes fadeInRightSide {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRightSide {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRightSide {
  -webkit-animation-name: fadeInRightSide;
  animation-name: fadeInRightSide;
}


@keyframes fadeOutBottom {
  0% {
    opacity: 1;
    transform: translatey(0)
  }

  to {
    opacity: 0;
    transform: translatey(60%)
  }
}
@-webkit-keyframes fadeOutBottom {
  0% {
    opacity: 1;
    transform: translatey(0)
  }

  to {
    opacity: 0;
    transform: translatey(60%)
  }
}
.fadeOutBottom {
  -webkit-animation-name: fadeOutBottom;
  animation-name: fadeOutBottom;
}


@keyframes fadeOutTop {
  0% {
    opacity: 0;
    transform: translatey(-50px)
  }

  to {
    opacity:1;
    transform: translatey(0%)
  }
}
@-webkit-keyframes fadeOutTop {
  0% {
    opacity: 1;
    transform: translatey(0)
  }

  to {
    opacity: 0;
    transform: translatey(-60%)
  }
}
.fadeOutTop {
  -webkit-animation-name: fadeOutTop;
  animation-name: fadeOutTop;
}



@keyframes fadeNavTop {
  0% {
    opacity: 0;
    transform: translatey(-50px)
  }

  to {
    opacity:1;
    transform: translatey(0%)
  }
}
@-webkit-keyframes fadeNavTop {
  0% {
    opacity: 0;
    transform: translatey(-50px)
  }

  to {
    opacity: 1;
    transform: translatey(0%)
  }
}
.fadeNavTop {
  -webkit-animation-name: fadeNavTop;
  animation-name: fadeNavTop;
}
@keyframes fadeNavTop2 {
  0% {
    opacity: 1;
    transform: translatey(0%)
  }

  to {
    opacity:0;
    transform: translatey(-50px)
  }
}
@-webkit-keyframes fadeNavTop2 {
  0% {
    opacity: 0;
    transform: translatey(-50px)
  }

  to {
    opacity: 1;
    transform: translatey(0%)
  }
}
.fadeNavTop2 {
  -webkit-animation-name: fadeNavTop2;
  animation-name: fadeNavTop2;
}

@keyframes fadeNavBotttom {
  0% {
    opacity: 1;
    transform: translatey(0)
  }

  to {
    opacity:0;
    transform: translatey(50px)
  }
}
@-webkit-keyframes fadeNavBotttom {
  0% {
    opacity: 1;
    transform: translatey(0)
  }

  to {
    opacity: 0;
    transform: translatey(50px)
  }
}
.fadeNavBotttom {
  -webkit-animation-name: fadeNavBotttom;
  animation-name: fadeNavBotttom;
}


@keyframes fadeNavBotttom2 {
  0% {
    opacity: 0;
    transform: translatey(50px)
  }

  to {
    opacity:1;
    transform: translatey(0)
  }
}
@-webkit-keyframes fadeNavBotttom2 {
  0% {
    opacity: 1;
    transform: translatey(0)
  }

  to {
    opacity: 0;
    transform: translatey(50px)
  }
}
.fadeNavBotttom2 {
  -webkit-animation-name: fadeNavBotttom2;
  animation-name: fadeNavBotttom2;
}

@keyframes fadeRightLeft {
  0% {
    transform: translateX(100%)
  }

  to {
    transform: translateX(0)
  }
}
.fadeRightLeft {
  -webkit-animation-name: fadeRightLeft;
  animation-name: fadeRightLeft;
}



.inview {
  opacity: 0;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  //opacity: 1;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes slideInUp {
  0% {
      -webkit-transform: translate3d(0, 50px, 0);
      transform: translate3d(0, 50px, 0);
      visibility: visible;
      opacity: 0;
  }

  100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
}

@keyframes slideInUp {
  0% {
      -webkit-transform: translate3d(0, 50px, 0);
      transform: translate3d(0, 50px, 0);
      visibility: visible;
      opacity: 0;
  }

  100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideInLeft {
  0% {
      -webkit-transform: translate3d(-50px, 0, 0);
      transform: translate3d(-50px, 0, 0);
      visibility: visible;
      opacity: 0;
  }

  100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
      -webkit-transform: translate3d(-50px, 0, 0);
      transform: translate3d(-50px, 0, 0);
      visibility: visible;
      opacity: 0;
  }

  100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
      -webkit-transform: translate3d(50px, 0, 0);
      transform: translate3d(50px, 0, 0);
      visibility: visible;
      opacity: 0;
  }

  100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
      -webkit-transform: translate3d(50px, 0, 0);
      transform: translate3d(50px, 0, 0);
      visibility: visible;
      opacity: 0;
  }

  100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
// .hover-scale {
//   transition: transform 0.05s ease-out;
//   &:hover {
//     transform: scale(1.02);
//   }
// }
.hover-scale {
  .article-cat {
    a {
      overflow: auto;
      border-radius: 0;
    }
  }



}

.article-img-wrapper ,
.list-item-img,
.calc-wrapper .article-column,
.list-item-left{

  & > a {
    border-radius: 10px;
    display: table;
    overflow: hidden;
    img {
      transition: transform 0.2s ease;;
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}
@keyframes bounce {
	0%, 100%, 20%, 50%, 80% {
		transform:         translateY(0) translateX(0);
	}
	40% {
		transform:         translateY(-15px) translateX(15px);
	}
	60% {
		transform:         translateY(-15px) translateX(15px);
	}
}
