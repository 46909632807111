.site-footer {
    // font-family: $SourceSans;
    position: relative;
    // overflow: hidden;

    a {
        color: $color-white;
        position: relative;

    }

    .site-footer-wrapper {
        min-height: 330px;
        position: relative;
        width: 100%;
        overflow: hidden;

        .footer-bg {

            background-color: $color-orange;
            position: absolute;
            top: 100px;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: -1;
            width: 100%;
            height: 100%;

        }

        .footer-bg-img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: -1;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: top;

            @media (max-width: 420px) {
                display: none;
            }
        }

        .footer-bg-img-mobile {
            display: none;

            @media (max-width: 420px) {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: -1;
                width: 100%;
                height: 100%;
                object-fit: none;
                object-position: top;
            }
        }

        .footer-top {
            text-align: center;
            margin-top: 100px;
            margin-bottom: 30px;
            padding: 0 15px;
            display: flex;
            justify-content: center;

            @media (max-width: $breakpoint-mobile-s) {
                justify-content: space-between;
                margin-top: 80px;
            }

            .footer-logo {
                margin-right: 40px;

                @media (max-width: $breakpoint-mobile-s) {
                    margin-right: 20px;
                }
            }

            .footer-logo2 {
                @media (max-width: $breakpoint-mobile-s) {
                    height: 95px;
                }
            }
        }

        .footer-nav {
            padding: 0 50px;
            margin-bottom: 32px;

            a {
                &::after {
                    content: "";
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    width: 0%;
                    height: 1px;
                    background-color: #fff;
                    transition: width 300ms cubic-bezier(0.22, 1, 0.36, 1);
                }

                &:hover {
                    &::after {
                        content: "";
                        width: 100%;
                        height: 1px;
                        background-color: #fff;
                        display: block;
                        position: absolute;
                    }
                }
            }

            @media (max-width: $breakpoint-mobile) {
                padding: 0 15px;
            }

            ul {
                list-style-type: none;
                display: flex;
                justify-content: space-around;

                @media (max-width: $breakpoint-desktops) {
                    flex-flow: wrap;
                    justify-content: flex-start;

                    li {
                        flex: 0 0 50%;
                        margin-bottom: 20px;

                        a {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        .footer-nav-pages {
            margin-bottom: 35px;

            a {
                &::after {
                    content: "";
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    width: 0%;
                    height: 1px;
                    background-color: #fff;
                    transition: width 300ms cubic-bezier(0.22, 1, 0.36, 1);
                }

                &:hover {
                    &::after {
                        content: "";
                        width: 100%;
                        height: 1px;
                        background-color: #fff;
                        display: block;
                        position: absolute;
                    }
                }
            }

            @media (max-width: $breakpoint-mobile) {}

            ul {
                list-style-type: none;
                display: flex;
                column-gap: 40px;
                justify-content: center;

                @media (max-width: $breakpoint-mobile) {
                    justify-content: space-between;
                    column-gap: normal;
                    padding: 0 15px;
                }
            }

        }

        .footer-btm {
            display: flex;
            padding: 0 35px;
            justify-content: flex-start;
            position: relative;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-desktops) {
                flex-direction: column;
                align-items: center;
                padding: 0 15px;
            }

            .footer-social {
                position: absolute;
                left: 0;
                right: 0;

                @media (max-width: $breakpoint-desktops) {
                    position: relative;
                    margin-bottom: 25px;
                }

                ul {
                    list-style-type: none;
                    display: flex;
                    column-gap: 29px;
                    justify-content: center;
                    margin-top: -13px;

                    @media (max-width: $breakpoint-desktops) {
                        justify-content: center;
                        column-gap: 25px;
                    }

                }
            }

            .footer-text {
                margin-bottom: 15px;
                color: $color-white;
                text-align: center;
            }

            .web-design {
                margin-left: auto;

                a {
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -2px;
                        left: 0;
                        width: 0%;
                        height: 2px;
                        background-color: #fff;
                        transition: width 300ms cubic-bezier(0.22, 1, 0.36, 1);
                    }

                    &:hover {
                        &::after {
                            content: "";
                            width: 100%;
                            height: 2px;
                            background-color: #fff;
                            display: block;
                            position: absolute;
                        }
                    }
                }

                @media (max-width: $breakpoint-desktops) {
                    margin: auto;
                }
            }
        }
    }
}
